@import url(https://fonts.googleapis.com/css2?family=Calistoga&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Calistoga&family=Rubik:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    background: rgba(255, 255, 255, 1);
  }
  
  #header.header-scrolled, #header.header-inner-pages {
    /* background: rgba(40, 58, 90, 0.9); template color */
    /* background: rgba(48,51,107, 0.8); first color test */
    background: rgba(255, 255, 255, 1);
  }
  
  #header .logo {
    font-size: 1.2rem;
    margin: 0;
    padding: 0 0.7rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: auto;
  }
  
  #header .siteLogo{
    display: flex;
    flex-direction: row;
  }
  #header .siteLogo img{
    width: 80px !important;
  }
  #header .logo {
    /* color: #fff; template color */
    font-family: 'Roboto', sans-serif;
    color: #161a16;
    transition: all ease-in-out 0.3s;
  }
  #header .siteLogo:hover .logo{
    color: #161a16;
  }
  
  #header .logo img {
    max-height: 2rem;
  }
  #header .navWrapper{
    justify-content: space-between;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  .header-scrolled .navbar .active {
    color: #161a16 !important;
  }
  .header-scrolled .navbar a{
    /* color: #fff; */
    color: #161a16;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 20px;
    font-size: 15px;
    font-weight: 600;
    color: #161a16;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #198450;
  }
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #198450;
    font-size: 28px;
    font-weight: 600;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(40, 58, 90, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #161a16;
  }
  
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #198450;
  }
:root {
  --primary-color: #198450;
  --primary-gradient: linear-gradient(90deg, #198450 0%, #25b06b 100%);
  --text-dark: #333333;
  --text-light: #ffffff;
  --background-light: #ffffff;
}

#hero {
  width: 100%;
  background: var(--background-light);
  color: var(--text-dark);
  padding: 20px 0;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}

/* .hero-content {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 60px;
} */

/* .hero-text {
  flex: 1;
} */

.hero-text h1 {
  color: var(--text-dark);
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.2;
}

/* .hero-text h2 {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
} */

/* .hero-text p {
  color: #666666;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 32px;
} */

 .hero-images {
  flex: 1 1;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.image-grid img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease;
}

.img-1 {
  animation: float 3s ease-in-out infinite;
}

.img-2 {
  margin-top: 40px;
  animation: float 3s ease-in-out infinite 0.5s;
}

.img-3 {
  grid-column: span 2;
  animation: float 3s ease-in-out infinite 1s;
} 
:root {
  --primary-green: #00ab55;
  --text-dark: #1a1a1a;
  --text-gray: #666666;
  --background: #ffffff;
}

.page-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.logo {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-dark);
}

.nav-links {
  display: flex;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
}

.nav-links a {
  color: var(--text-dark);
  text-decoration: none;
  font-size: 16px;
}


.hero-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  color: var(--primary-green);
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.1;
}

.rocket {
  font-size: 48px;
  vertical-align: middle;
}

.hero-content h2 {
  color: var(--text-dark);
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 24px;
}

.hero-content p {
  color: var(--text-gray);
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
}

.get-started {
  background: var(--primary-green);
  color: white;
  border: none;
  padding: 14px 32px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started:hover {
  background: #009648;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 40px;
  }

  .hero-content h2 {
    font-size: 28px;
  }

  .nav-links {
    display: none;
  }
}


/* Product Highlight Section */
.product-highlight {
  background: var(--background-light);
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 60px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

.product-highlight h3 {
  color: var(--text-dark);
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
}

.product-content {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  align-items: center;
}

.product-text {
  flex: 1 1;
}

.product-text h4 {
  color: var(--primary-color);
  font-size: 32px;
  margin-bottom: 20px;
}

.product-text p {
  color: #666666;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.product-text ul {
  list-style: none;
  padding: 0;
  margin-bottom: 32px;
}

.product-text li {
  color: #666666;
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;
}

.product-text li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.btn-primary {
  background: var(--primary-gradient);
  color: var(--text-light);
  padding: 12px 32px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-2px);
}

/* Stats Section */
.stats-section {
  background: var(--primary-gradient);
  border-radius: 20px;
  padding: 40px;
  color: var(--text-light);
}

.stats-section h3 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 40px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;
  gap: 32px;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 8px;
}

.stat-label {
  font-size: 16px;
  margin-bottom: 4px;
}

.stat-date {
  font-size: 14px;
  opacity: 0.8;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-content,
  .product-content {
    flex-direction: column;
    text-align: left; /* Ensure left alignment on smaller screens */
    align-items: flex-start; /* Keep content aligned to the left */
  }

  .product-text ul {
    text-align: left;
  }

  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 36px;
    text-align: left; /* Ensure left alignment */
  }

  .hero-text h2 {
    font-size: 24px;
    text-align: left; /* Ensure left alignment */
  }

  .product-highlight h3 {
    font-size: 28px;
    text-align: left; /* Keep text aligned left */
  }

  .product-text h4 {
    font-size: 24px;
    text-align: left;
  }

  .image-grid {
    grid-template-columns: 1fr; /* Stack images vertically */
  }

  .img-2 {
    margin-top: 0;
  }

  .img-3 {
    grid-column: auto;
  }
}

@media (max-width: 480px) {
  .stats-grid {
    grid-template-columns: 1fr; /* Stack stats items vertically */
  }
}

@media (prefers-reduced-motion: reduce) {
  .image-grid img {
    animation: none;
  }

  .btn-primary {
    transition: none;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li + li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #161a16;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.3s;
    line-height: 1;
    color: #47b2e4;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #47b2e4;
  }
  
  .about .content .btn-learn-more:hover {
    background: #47b2e4;
    color: #fff;
    text-decoration: none;
  }

  .row.content {
    font-size: 19px;
  }

  @media (max-width:468px) {
    .about p{
      width: 350px;
      padding: 0 1rem;
      margin: auto;
    }
  }
  @media (max-width:330px) {
    .about p{
      width: 100%;
    }
  }
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
    border-radius: 1.4rem;
    min-height: 302px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .services .icon-box .icon {
    margin-bottom: 10px;
    display: flex;
  }
  .services .icon-box img{
    width: 6rem;
    margin: auto;
  }
  .services .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
  }
  
  .services .icon-box h4 {
    /* color: #37517e; */
    color: #198450;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    padding: 0 2rem;
  }
  
  .services .icon-box:hover {
    transform: translateY(-10px);
  }
  
  .services .icon-box:hover h4 {
    color: #198450;
  }
  @media (max-width:420px) {
    .services .section-title p{
      width: 330px;
      margin: auto;
    }
    .services .icon-box p{
      width: 230px;
      padding: 0;
      margin: auto;
    }
    .services .apps  h4{
      width: 236px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
  @media (max-width:352px) {
    .services .section-title p{
      width: 100% ;
    }
  }
  @media (max-width:330px) {
    
    .services .icon-box p{
      width: 100%;
    }
    .services .apps  h4{
      width: 100%;
    }
  }
  
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    /* background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../../img/cta-bg.jpg") fixed center center; */
    background: linear-gradient(rgba(89, 150, 115, 1), rgb(64, 206, 118)), url(/static/media/cta-bg-1.d27b16fe.jpg) fixed center center;
    background-size: cover;
    padding: 120px 0;
  }

  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  @media (min-width:768px) {
    .cta p{
      padding: 0 2rem 0 0rem;
      width: 93%;
    }
  }
  @media (max-width:427px) {
    .cta h3{
      padding: 0 2rem;
    }
  }
  
  .cta .cta-btn {
    /* font-family: "Jost", sans-serif; */
    font-family: 'Rubik',sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #599673;
    border: 2px solid #599673;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  @media (max-width: 420px) {
    .cta p{
      width: 296px;
      margin: auto;
    }
  }
  @media (max-width:319px) {
    .cta p{
      width: 100%;
    }
  }
.portfolio .portfolio-content{
  margin: 0.6rem 0;
  background: #fff;
  border-radius: 15px;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  box-shadow: 0px 2px 15px#198450;
}
.portfolio .portfolio-content img{
  width: 80%;
  border-radius: 15px;
  margin: 1.2rem auto;
}
.portfolio .portfolio-content.reverse img{
  padding: 3.3rem;
}
@media (min-width:968px) {
  .portfolio-content.reverse .contentBlock{
    margin-left: 2.5rem !important;
  }
  .portfolio-content.yip p{
    padding-right: 2rem;
  }
}
@media (max-width:968px) {
  .portfolio-content.reverse .contentBlock{
    margin-top: -2.5rem !important;
  }
}
.portfolio .portfolio-content .img{
  padding: 0 2rem;
  display: flex;
}
.portfolio .portfolio-content .img , .portfolio .portfolio-content .portfolio-details{
  flex: 50% 1;
} 
.portfolio .portfolio-content .contentBlock{
  margin: auto;
  padding: 2rem;
}
.portfolio .portfolio-content.reverse{
  flex-direction: row-reverse;
}
.portfolio-content .social-icons{
  display: flex;
  flex-direction: row;
  margin-top: 1.8rem;
  padding: 1rem 0;
}
.portfolio .portfolio-content .social-icons i{
  color: #198450;
  width: 2.3rem;
  height: 2.3rem;
  margin: 0.1rem 0.6rem;
  border-radius: 50%;
  background: #eff2f8;
  display: flex;
  transition: all ease-in-out 0.4s;
}
.portfolio-content .social-icons i::before{
  font-size: 1.23rem;
  margin: auto;
}
.portfolio .portfolio-content , .portfolio .portfolio-content{
  margin: 2rem 0;
}
.portfolio .portfolio-content:hover {
  transform: translateY(-10px);
}
.portfolio .portfolio-content .social-icons i:hover{
  color: #fff;
  background: #198450;
  transform: translateY(-2px);
}
@media (max-width:968px) {
  .portfolio .portfolio-content img{
    width: 100%;
    padding: 0;
  }
  .portfolio .portfolio-content , .portfolio .portfolio-content.reverse{
    flex-direction: column;
  }
}
@media (max-width:411px) {
  .portfolio .section-title p{
    width: 280px;
    margin: auto;
  }
}
@media (max-width:319px) {
  .portfolio .section-title p{
    width: 100%;
  }
}
/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
    height: 100%;
  }
  
  .team .member .pic {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
  }
  
  .team .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team .member:hover {
    transform: translateY(-10px);
  }
  
  .team .member .member-info {
    padding-left: 30px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #198450;
  }
  
  .team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team .member span::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
  }
  
  .team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #eff2f8;
  }
  
  .team .member .social a i {
    color: #198450;
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team .member .social a:hover {
    background: #198450;
  }
  
  .team .member .social a:hover i {
    color: #fff;
  }
  
  .team .member .social a + a {
    margin-left: 8px;
  }
  @media (min-width:992px) {
    .team .member{
      max-height: 290px;
    }
    .team .member{
      align-items: center;
    }
    .team .member-info{
      margin: auto;
    }
  }
  @media (max-width:992px) {
    .team .member{
      flex-direction: column;
    }
    .team .member .pic{
      display: flex;
      width: 60%;
      margin: auto;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 365px) {
    .team .section-title p{
      width: 296px;
      margin: auto;
    }
  }
  @media (max-width:319px) {
    .team .section-title p{
      width: 100%;
    }
  }
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    border-top: 3px solid #198450;
    border-bottom: 3px solid #198450;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  }
  
  .contact .info i {
    font-size: 20px;
    color: #091e09;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #198450;
  }
  
  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #198450;
  }
  
  .contact .info .email p {
    padding-top: 5px;
  }
  
  .contact .info .social-links {
    padding-left: 60px;
  }
  
  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }
  
  .contact .info .social-links a:hover {
    background: #198450;
    color: #fff;
  }
  
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #198450;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    border-top: 3px solid #198450;
    border-bottom: 3px solid #198450;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #198450;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #198450;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact .php-email-form label {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #198450;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form input[type="submit"] {
    background: #198450;
    border: 0;
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form input[type="submit"]:hover {
    background: #198450;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    font-size: 14px;
    background:var(--primary-gradient)
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: #444444;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #198450;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #198450;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #198450;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    color: #198450;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    color: #5e5e5e;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #198450;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #198450;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #198450;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #198450;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #198450;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
  }
  
  #footer .copyright {
    float: left;
  }
  
  #footer .credits {
    float: right;
    font-size: 13px;
  }
  
  #footer .credits a {
    transition: 0.3s;
  }
  
  @media (max-width: 768px) {
    #footer .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    #footer .copyright, #footer .credits {
      text-align: center;
      float: none;
    }
    #footer .credits {
      padding-top: 4px;
    }
  }
  
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #00080b;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #599673;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* Loading the Font */
/* Caslitoga */
/* Rubik */
/* Roboto */
html{
  scroll-behavior: smooth;
}
body {
    font-family: 'Rubik', sans-serif;
    color: #444444;
  }
  
  a {
    color: #161a16;
    text-decoration: none;
  }
  
  a:hover {
    color: #22f588;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    /* font-family: "Jost", sans-serif; */
    /* font-family: 'Calistoga', cursive; */
    font-family: 'Rubik',sans-serif;
    font-weight: 800 !important;
  }
  /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 130px 0;
  /* padding-top: 130px; */
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  /* color: #37517e; */
  color:  #161a16;
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #161a16;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}
/* Animations*/
  
  @keyframes up-down {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }

.comming-soon{
    margin-top: 110px;
}
.comming-soon h1{
    margin: 2rem 0 0 0 ;
    text-align: center;
    color:  #599673;
    font-size: 48px;
}
.imgContainer{
    display: flex;
}
.commingSoonImg{
    width: 25rem;
    margin: auto;
    animation:  up-down 2s ease-in-out infinite alternate-reverse both;
}


.loader { 
    width:200px; 
    margin:0 auto;
    border-radius:10px;
    border:4px solid transparent;
    position:relative;
    padding:5px;
  }
  .loader:before {
    content:'';
    border:1px solid #599673; 
    border-radius:10px;
    position:absolute;
    top:-4px; 
    right:-4px; 
    bottom:-4px; 
    left:-4px;
  }
  .loader .loaderBar { 
    position:absolute;
    border-radius:10px;
    padding: 5px;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    background:#599673; 
    width:0;
    animation:borealisBar 2s linear infinite;
  }
  
  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }
