/* Animations*/
@-webkit-keyframes up-down {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }

.comming-soon{
    margin-top: 110px;
}
.comming-soon h1{
    margin: 2rem 0 0 0 ;
    text-align: center;
    color:  #599673;
    font-size: 48px;
}
.imgContainer{
    display: flex;
}
.commingSoonImg{
    width: 25rem;
    margin: auto;
    animation:  up-down 2s ease-in-out infinite alternate-reverse both;
}


.loader { 
    width:200px; 
    margin:0 auto;
    border-radius:10px;
    border:4px solid transparent;
    position:relative;
    padding:5px;
  }
  .loader:before {
    content:'';
    border:1px solid #599673; 
    border-radius:10px;
    position:absolute;
    top:-4px; 
    right:-4px; 
    bottom:-4px; 
    left:-4px;
  }
  .loader .loaderBar { 
    position:absolute;
    border-radius:10px;
    padding: 5px;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    background:#599673; 
    width:0;
    animation:borealisBar 2s linear infinite;
  }
  
  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }