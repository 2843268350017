:root {
  --primary-color: #198450;
  --primary-gradient: linear-gradient(90deg, #198450 0%, #25b06b 100%);
  --text-dark: #333333;
  --text-light: #ffffff;
  --background-light: #ffffff;
}

#hero {
  width: 100%;
  background: var(--background-light);
  color: var(--text-dark);
  padding: 20px 0;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}

/* .hero-content {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 60px;
} */

/* .hero-text {
  flex: 1;
} */

.hero-text h1 {
  color: var(--text-dark);
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.2;
}

/* .hero-text h2 {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
} */

/* .hero-text p {
  color: #666666;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 32px;
} */

 .hero-images {
  flex: 1;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.image-grid img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease;
}

.img-1 {
  animation: float 3s ease-in-out infinite;
}

.img-2 {
  margin-top: 40px;
  animation: float 3s ease-in-out infinite 0.5s;
}

.img-3 {
  grid-column: span 2;
  animation: float 3s ease-in-out infinite 1s;
} 
:root {
  --primary-green: #00ab55;
  --text-dark: #1a1a1a;
  --text-gray: #666666;
  --background: #ffffff;
}

.page-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.logo {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-dark);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 32px;
}

.nav-links a {
  color: var(--text-dark);
  text-decoration: none;
  font-size: 16px;
}


.hero-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  color: var(--primary-green);
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.1;
}

.rocket {
  font-size: 48px;
  vertical-align: middle;
}

.hero-content h2 {
  color: var(--text-dark);
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 24px;
}

.hero-content p {
  color: var(--text-gray);
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
}

.get-started {
  background: var(--primary-green);
  color: white;
  border: none;
  padding: 14px 32px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started:hover {
  background: #009648;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 40px;
  }

  .hero-content h2 {
    font-size: 28px;
  }

  .nav-links {
    display: none;
  }
}


/* Product Highlight Section */
.product-highlight {
  background: var(--background-light);
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 60px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

.product-highlight h3 {
  color: var(--text-dark);
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
}

.product-content {
  display: flex;
  gap: 40px;
  align-items: center;
}

.product-text {
  flex: 1;
}

.product-text h4 {
  color: var(--primary-color);
  font-size: 32px;
  margin-bottom: 20px;
}

.product-text p {
  color: #666666;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.product-text ul {
  list-style: none;
  padding: 0;
  margin-bottom: 32px;
}

.product-text li {
  color: #666666;
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;
}

.product-text li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.btn-primary {
  background: var(--primary-gradient);
  color: var(--text-light);
  padding: 12px 32px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-2px);
}

/* Stats Section */
.stats-section {
  background: var(--primary-gradient);
  border-radius: 20px;
  padding: 40px;
  color: var(--text-light);
}

.stats-section h3 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 40px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 8px;
}

.stat-label {
  font-size: 16px;
  margin-bottom: 4px;
}

.stat-date {
  font-size: 14px;
  opacity: 0.8;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-content,
  .product-content {
    flex-direction: column;
    text-align: left; /* Ensure left alignment on smaller screens */
    align-items: flex-start; /* Keep content aligned to the left */
  }

  .product-text ul {
    text-align: left;
  }

  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 36px;
    text-align: left; /* Ensure left alignment */
  }

  .hero-text h2 {
    font-size: 24px;
    text-align: left; /* Ensure left alignment */
  }

  .product-highlight h3 {
    font-size: 28px;
    text-align: left; /* Keep text aligned left */
  }

  .product-text h4 {
    font-size: 24px;
    text-align: left;
  }

  .image-grid {
    grid-template-columns: 1fr; /* Stack images vertically */
  }

  .img-2 {
    margin-top: 0;
  }

  .img-3 {
    grid-column: auto;
  }
}

@media (max-width: 480px) {
  .stats-grid {
    grid-template-columns: 1fr; /* Stack stats items vertically */
  }
}

@media (prefers-reduced-motion: reduce) {
  .image-grid img {
    animation: none;
  }

  .btn-primary {
    transition: none;
  }
}
