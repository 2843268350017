/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    /* background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../../img/cta-bg.jpg") fixed center center; */
    background: linear-gradient(rgba(89, 150, 115, 1), rgb(64, 206, 118)), url("../../img/cta-bg-1.jpg") fixed center center;
    background-size: cover;
    padding: 120px 0;
  }

  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  @media (min-width:768px) {
    .cta p{
      padding: 0 2rem 0 0rem;
      width: 93%;
    }
  }
  @media (max-width:427px) {
    .cta h3{
      padding: 0 2rem;
    }
  }
  
  .cta .cta-btn {
    /* font-family: "Jost", sans-serif; */
    font-family: 'Rubik',sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #599673;
    border: 2px solid #599673;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  @media (max-width: 420px) {
    .cta p{
      width: 296px;
      margin: auto;
    }
  }
  @media (max-width:319px) {
    .cta p{
      width: 100%;
    }
  }