/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
    height: 100%;
  }
  
  .team .member .pic {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
  }
  
  .team .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team .member:hover {
    transform: translateY(-10px);
  }
  
  .team .member .member-info {
    padding-left: 30px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #198450;
  }
  
  .team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team .member span::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
  }
  
  .team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #eff2f8;
  }
  
  .team .member .social a i {
    color: #198450;
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team .member .social a:hover {
    background: #198450;
  }
  
  .team .member .social a:hover i {
    color: #fff;
  }
  
  .team .member .social a + a {
    margin-left: 8px;
  }
  @media (min-width:992px) {
    .team .member{
      max-height: 290px;
    }
    .team .member{
      align-items: center;
    }
    .team .member-info{
      margin: auto;
    }
  }
  @media (max-width:992px) {
    .team .member{
      flex-direction: column;
    }
    .team .member .pic{
      display: flex;
      width: 60%;
      margin: auto;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 365px) {
    .team .section-title p{
      width: 296px;
      margin: auto;
    }
  }
  @media (max-width:319px) {
    .team .section-title p{
      width: 100%;
    }
  }